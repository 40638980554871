<template>
  <div class="pageContainer targetPage flexCloumn">
    <el-row class="searchBar flexBetween" style="flex-wrap: wrap;width:100%">
      <div class="flexStart flex_1">
        <!-- <span>考核对象：</span>
        <el-select v-model="queryForm.compute_type" size="small" style="width:120px">
          <el-option label="学生" :value="1"></el-option>
          <el-option label="教师" :value="2"></el-option>
        </el-select> -->


        <span v-if="queryForm.compute_type == 1" style="margin-left:10px">选择班级：</span>

        <tchtree v-model="queryForm.class_id" v-if="queryForm.compute_type == 1" @change="getList"></tchtree>

        <!-- <el-select clearable v-if="queryForm.compute_type == 1" v-model="queryForm.class_id" size="small"
          style="width:180px">
          <el-option label="全部" value=""></el-option>
          <el-option :label="item.name" v-for="(item, idx) in ckdTreeData" :key="idx" :value="item.id"></el-option>
        </el-select> -->



        <span style="margin-left:10px">考核周期：</span>
        <el-date-picker size="small" style="width:120px" v-model="queryForm.month" type="month" value-format="yyyy-MM"
          placeholder="选择月">
        </el-date-picker>

        <el-button @click="getList" icon="el-icon-search" type="primary" size="small" style="margin-left:10px">查询
        </el-button>
      </div>

      <div class="flexStart"
        style="color: #3378fa;font-size: 14px;font-weight: 700;margin: 0 5px 0 30px;cursor: pointer;">

        <el-button type="primary" plain @click="refreshList" icon="el-icon-refresh" size="small" style="margin-left:10px">
          重新计算
        </el-button>

        <el-button type="primary" plain @click="exportXLS" icon="el-icon-download" size="small" style="margin-left: 10px;">导出</el-button>

      </div>
    </el-row>
    <div class="dividerBar"></div>

    <el-table v-tableFit :data="DataList" height="100%" size="small" stripe border style="width:fit-content;width:100%;">
      <el-table-column type="index" width="65" label="序号" align="center" />
      <el-table-column prop="stu_code" label="学号" width="120" align="center" />

      <el-table-column prop="stu_name" label="学生姓名" width="100" align="center" />
      <el-table-column prop="class_name" label="所在班级" width="120" align="center" />

      <el-table-column prop="sx_score" v-if="jxConfig['sx_sq_score']" label="实习得分" min-width="100" align="center" />

      <el-table-column prop="dk_score" v-if="jxConfig['xs_dk_score']" label="打卡得分" min-width="100" align="center" />
      <el-table-column prop="zbtj_score" v-if="jxConfig['xs_zbtj_score']" label="周报得分" min-width="100" align="center" />
      <el-table-column prop="ybtj_score" v-if="jxConfig['xs_ybtj_score']" label="月报得分" min-width="100" align="center" />
      <el-table-column prop="dwkp_score" v-if="jxConfig['dwkp_score']" label="单位考评得分" min-width="100" align="center" />
      <el-table-column prop="total_score" label="总分" min-width="100" align="center" />
      <el-table-column prop="compute_month" label="考评月份" min-width="100" align="center">
        <template slot-scope="scope">

          {{ scope.row.compute_year }}年 {{ scope.row.compute_month }}月
        </template>

      </el-table-column>
      <el-table-column prop="cdate" label="考评时间" width="200" align="center" />





      <!-- <el-table-column fixed="right" label="操作" width="90">
          <template slot-scope="scope">

            <el-button type="text" size="small" @click="viewInfo(scope.row)" v-if="scope.row.report_status == 0">
              审批
            </el-button>

            <el-button v-else type="text" size="small" @click="viewInfo(scope.row)">详情</el-button>

          </template>
        </el-table-column> -->
    </el-table>

    <el-pagination @current-change="handlePageChange" @size-change="handleSizeChange" :current-page="page.current_page"
      :page-size="page.per_page" :total="page.count" layout="total, sizes,prev, pager, next"
      :page-sizes="[10, 20, 50, 100]" style="margin-top: 10px;">
    </el-pagination>
  </div>
</template>
  
<script>
import util from "../../utils/util"
import tchtree from '../com/tchTree.vue'
import {njsyxx_statistics_export_excel} from "../../../public/js/Export2Excel";

export default {
  components: {
    tchtree
  },
  data() {
    return {
      dialogShow: true,
      queryForm: {
        month: util.formatDate(),
        compute_type: 1,
        class_id: ""
      },
      page: {
        current_page: 1,
        next_page: 2,
        pre_page: 50,
        per_page: 50,
        total_page: 0,
        count: 0
      },
      DataList: [],
      ckdTreeData: [],
      columnList: [],
      jxConfig: {},//绩效考核配置，绩效分数为0时，不显示此列
    };
  },
  mounted() {
    this.getTree()
   

    this.getJxConfig()
  },
  methods: {

    getJxConfig() {
      this.$http.post("/api/performance_appraisal_select", {
        appraisal_type: "1"
      }).then(res => {
        let jxConfig = {}
        for (let item of res.data) {
          jxConfig[item.appraisal_code + "_score"] = item.appraisal_score
        }

        this.jxConfig = jxConfig
        console.log(jxConfig)
        this.getList()
       
      })
    },


    getTree() {

      this.$http.post("/api/get_tch_cls_data", {}).then(res => {
        res.data.bj_list.map(e => {
          e.name = e.upname + "/" + e.name
        })


        this.treeData = res.data.bj_list
        this.ckdTreeData = this.treeData
      })
    },
    getList() {

      this.$http.post("/api/performance_score_list", {
        page: this.page.current_page,
        pagesize: this.page.per_page,
        compute_year: this.queryForm.month.split("-")[0],
        compute_month: this.queryForm.month.split("-")[1],
        compute_type: this.queryForm.compute_type,
        class_id: this.queryForm.class_id
      }).then(res => {
        this.DataList = res.data.data
        this.page = res.data.page
      })
    },
    handlePageChange(val) {
      this.page.current_page = val
      this.getList(val)
    },
    handleSizeChange(val) {
      this.page.current_page = 1;
      this.page.per_page = val;
      this.getList(1)
    },
    refreshList() {

      this.$http.post("/api/performance_calculation", {
        compute_type: 1,
        year: this.queryForm.month.split("-")[0],
        month: this.queryForm.month.split("-")[1],
      }).then(res => {
        this.$message.success("后台计算中，请等待1分钟左右刷新页面")
        this.getList()
      })
    },
    exportXLS() {
      this.$http.post('/api/export_xskh_data', {
        pagesize: 100000,
        compute_year: this.queryForm.month.split("-")[0],
        compute_month: this.queryForm.month.split("-")[1],
        compute_type: 1,
        class_id: this.queryForm.class_id
      }).then(res => {
        let data = res.data.data
        if (!data.length) {
          this.$message.warning('没有数据导出')
          return
        }
        let excelData = [
            [`${this.queryForm.month}学生考核`],
            ['学号','学生姓名','所在班级','实习得分','打卡得分','周报得分','月报得分','总分','考评月份','考评时间'],
        ]
        data.map(d => {
          excelData.push([
              d.stu_code,
              d.stu_name,
              d.class_name,
              d.sx_score,
              d.dk_score,
              d.zbtj_score,
              d.ybtj_score,
              d.total_score,
              d.compute_year + '年 ' + d.compute_month + '月',
              d.cdate,
          ])
        })
        njsyxx_statistics_export_excel(excelData,10)
      })
    },
  },


};
</script>
  
<style lang="less" scoped></style>